import React, { useEffect } from "react"
import saga from "./saga"
import { appActions } from "commons/reducer"
import { useHistory } from "react-router"
import { contractActions } from "./reducer"
import styles from "./styles"

import { composedComponent, formatInteger, setInStorage } from "utils/functions"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core"
import DoughnutChart from "commons/components/DoughnutChart"
import { Skeleton } from "@material-ui/lab"

const countWorkshopServices = (services) => {
  if (!services || services.length === 0) return 0
  const workshopServices = services.filter(
    (service) =>
      service.hasInvoices !== "Facturado" &&
      service.budgets.length > 0 &&
      (service.status === "En espera" || service.status === "Aprobado")
  )
  return workshopServices.length
}

const countWaitingServices = (services) => {
  if (!services || services.length === 0) return 0

  const waitingServices = services.filter(
    (service) =>
      service.hasInvoices !== "Facturado" &&
      service.budgets.length > 0 &&
      service.status === "En espera"
  )
  return waitingServices.length
}

const countWaitingInvoiceServices = (services) => {
  if (!services || services.length === 0) return 0

  const waitingInvoiceServices = services.filter((service) =>
    service.events.some(
      (event) => event.eventType.name === "Equipo entregado en espera de facturación"
    )
  )
  return waitingInvoiceServices.length
}

const Contracts = (props) => {
  const { actions, contracts, classes, loading } = props

  const history = useHistory()

  const contractValues = Object.values(contracts)

  useEffect(() => {
    actions.getContracts()
  }, [])

  const handleClick = (contract) => {
    actions.setContract(contract)
    setInStorage("contract", contract)
    history.push("/home")
  }
  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item style={{ marginTop: "32px" }} xs={12}>
        <Typography variant="h4">Resumen servicios contratados</Typography>
      </Grid>
      {contractValues.map((contract) => (
        <Grid item xs={4} key={contract.id}>
          <Card className={classes.root}>
            <CardContent>
              <Avatar
                alt={`Logo ${contract.name}`}
                src={contract.logo || ""}
                className={classes.large}
              />
              <Typography variant="h5" component="h2">
                {contract.name}
              </Typography>
              {loading ? (
                <Skeleton variant="rect" height={"350px"} />
              ) : (
                <>
                  <Typography>Presupuesto: ${formatInteger(contract.budget)}</Typography>
                  <DoughnutChart services={contract.services} contractBudget={contract.budget} />
                  <Typography variant="h6" component="h3">
                    Resumen
                  </Typography>
                  <Grid container>
                    <Grid item xs>
                      Equipos de taller
                    </Grid>
                    <Grid item xs>
                      <Typography component="div">
                        <Box textAlign="right" fontWeight="fontWeightBold">
                          {countWorkshopServices(contract.services)}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs>
                      Equipos en espera
                    </Grid>
                    <Grid item xs>
                      <Typography component="div">
                        <Box textAlign="right" fontWeight="fontWeightBold">
                          {countWaitingServices(contract.services)}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs>
                      Equipos en espera de facturación
                    </Grid>
                    <Grid item xs>
                      <Typography component="div">
                        <Box textAlign="right" fontWeight="fontWeightBold">
                          {countWaitingInvoiceServices(contract.services)}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </CardContent>
            <CardActions>
              <Button variant="contained" color="primary" onClick={() => handleClick(contract)}>
                Ver más
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default composedComponent(Contracts, saga, {
  saga: "sagaContracts",
  middlewares: [withStyles(styles)],
  states: ["contracts.contracts", "contracts.loading", "app.objects.contracts"],
  actions: [contractActions, appActions],
})
